import React, { useEffect ,useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";

export default function CreateAd() {
  useEffect(()=>{
    let data = sessionStorage.getItem('key')
    
    // console.log(data)
    if(data == null){
      window.location.href = "/#/";
    }
  }
  )
  const [headline, setheadline] = useState("");
  const [link, setlink] = useState("");
  const [priority, setpriority] = useState(0);
  const [photo, setphoto] = useState("");

  function add() {
    let content = {
      headline: headline,
      link: link,
      priority: priority,
      photo: photo,
    };
    axios({
      method: "post",
      url: "https://jtback.onrender.com/uploadAd",
      data: content,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 201) {
          console.log("success");
          alert("success");
          window.location.reload();
        }
      })
      .catch((err) => {
        alert("bhag maza nai aya");
        if (err.response) {
          alert("bhag maza nai aya");
          // console.log(err.response.data)
          // console.log(err.response.status)
          // console.log(err.response.headers)
          console.log(err.response.data.message);
        }
      });
  }
  const [loading, setloading] = useState(false);

  const uploadImage = async (e) => {
    const files = e.target.files;
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "jtimages");
    setloading(true);

    const res = await fetch(
      "https://api.cloudinary.com/v1_1/jhansitimes/image/upload",
      {
        method: "POST",
        body: data,
      }
    );

    const file = await res.json();
    console.log(file);
    setphoto(file.url);
    setloading(false);
  };

  return (
    <div style={{ marginLeft: "auto", marginRight: "auto", width: "50%" }}>
      <div>
        <label>Headline</label>
        <input
          type="text"
          className="form-control"
          style={{ width: "100%" }}
          value={headline}
          onChange={(e) => {
            setheadline(e.target.value);
          }}
        />
      </div>
      <div>
        <label>priority</label>
        <input
          type="text"
          className="form-control"
          style={{ width: "100%" }}
          value={priority}
          onChange={(e) => {
            setpriority(e.target.value);
          }}
        />
      </div>
      <div>
        <label>link</label>
        <input
          type="text"
          className="form-control"
          style={{ width: "100%" }}
          value={link}
          onChange={(e) => {
            setlink(e.target.value);
          }}
        />
      </div>
      <div className="App">
        <label>Photo</label>
        <input
          className="form-control"
          type="file"
          name="file"
          placeholder="choose"
          onChange={uploadImage}
        ></input>
        {loading ? (
          <h3>Loading...</h3>
        ) : (
          <img
            style={{ marginLeft: "25%" }}
            src={photo}
            height="150px"
            alt="broken"
          ></img>
        )}
      </div>
      <button
        type="button"
        style={{ width: "50%", marginLeft: "25%" }}
        className="btn btn-success"
        onClick={(e) => {
          e.preventDefault();
          add();
        }}
      >
        Submit
      </button>
    </div>
  );
}
