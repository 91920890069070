const tag = [
    {
        value:1,
        label:"jhansi"
      },
      {
        value:2,
        label:"desh"
      },
      {
        value:3,
        label:"up"
      },
      {
        value:4,
        label:"mp"
      },
      {
        value:5,
        label:"politics"
      },
      {
        value:6,
        label:"entertainment"
      },
      {
        value:7,
        label:"bundelkhand"
      },
      {
        value:8,
        label:"international"
      },
      {
        value:9,
        label:"breaking_news"
      }
      ,{
          value:10,
          label:"himachal"
      },
      {
        value:11,
        label:"sports"
    }
]

export default tag;
