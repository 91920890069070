import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: "40vh",
    objectFit: "cover",
  },
});

const FullNewsCard = (props) => {
  const classes = useStyles();
  const { date, title, brief, imgSrc, description, loading } = props;

  return (
    <React.Fragment>
      {loading ? (
        <Grid item xs={12}>
          <Skeleton variant="rect" height="50vh" />
          <Skeleton />
          <Skeleton width="60%" />
        </Grid>
      ) : (
        <Card className={classes.root}>
          <CardHeader title={title} subheader={date} />
          <CardMedia className={classes.media} src={imgSrc} component="img" />
          <CardContent>
            <React.Fragment>
              <Typography gutterBottom variant="h5">
                {brief}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="h3">
                {Array.isArray(description)
                  ? description.map((item) => {
                      return <p key={item}>{item}</p>;
                    })
                  : null}
              </Typography>
            </React.Fragment>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
};

export default FullNewsCard;
