import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyle = makeStyles({
  gridBorder: {
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
  },
  gridPadding: {
    padding: "10px",
  },
});

const Footer = () => {
  const classes = useStyle();
  return (
    <div style={{ position: "relative", marginTop: "10px" }}>
        <Grid
          item
          xs={12}
          className={`${classes.gridBorder} ${classes.gridPadding}`}
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <Typography align="center" gutterBottom>
            Designed & Developed with 💗 by {<a target="_blank" rel="noreferrer" href="https://coval.tech">coval.tech</a>}
          </Typography>
        </Grid>
     </div>
  );
};

export default Footer;
