import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import FullNewsCard from "./FullNewsCard";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router";

const FullNews = () => {
  useEffect(()=>{
    let data = sessionStorage.getItem('key')
    
    // console.log(data)
    if(data == null){
      window.location.href = "/#/";
    }
  }
  )
  const { id } = useParams();
  const url = `https://jtback.onrender.com/readNews/${id}`;
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("FullNews Mounted");
    axios.get(url).then((res) => {
      setNews(res.data);
      setLoading(false);
    });
  }, [url]);

  return (
    <Grid container>
        <Grid xs={0} sm={2} />
        <Grid item container sm={8} spacing={3}>
          <Grid item xs={12} sm={12}>
            <FullNewsCard
              date={news ? moment(news.updatedAt).format("LLL") : ""}
              brief={news ? news.brief : ""}
              title={news ? news.headline : ""}
              description={news ? news.description : ""}
              imgSrc={news ? news.cphoto : ""}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid xs={0} sm={2} />
      </Grid>
  )
}

export default FullNews;
