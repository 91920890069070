import React from "react";
import { Grid, Paper, ThemeProvider, makeStyles } from "@material-ui/core";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import theme from "./../theme";
import AdminHomeCard from "./Content/AdminHomeCard";
import { Route, Switch } from "react-router-dom";
import AdminNews from "./Content/AdminNews";
import Test2 from "./Test2/Test2"
import Update from "./Update/Update"
import FullNews from "./Content/FullNews"
import CreateAd from "./CreateAd/CreateAd"
import Admin from "./Admin/Admin"


const useStyle = makeStyles({
  root: {
    background: '#ffffff url("hero-bg.png") no-repeat fixed right top',
    backgroundSize: "cover",
  },
});

const App = () => {
  
  const classes = useStyle();

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} className={classes.root}>
        <Grid container direction="column" className={classes.root}>
          <Grid item>
            <Header />
          </Grid>
          <Grid item>
            <Switch>
              <Route exact path="/">
                <Admin />
              </Route>
              <Route path="/dashboard" component={AdminHomeCard} />
              <Route path="/FullNews/:id" component={FullNews} />
              <Route path="/AdminNews" component={AdminNews} />
              <Route path="/Create" component={Test2} />
              <Route path="/UpdateNews/:id" component={Update} />
              <Route path="/CreateAd" component={CreateAd} />
            </Switch>
          </Grid>
          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
};

export default App;
