import { Grid } from "@material-ui/core";
import React,{useState, useEffect} from "react";
import NewsCard from "./NewsCard";
import axios from "axios";
import moment from "moment";
import Preloader from '../Preloader/Preloader'

const AdminNews = () => {
  const [loading,setloading] = useState(true)
  const url = `https://jtback.onrender.com/temp`;
  const [news, setNews] = useState([]);
  useEffect(()=>{
    let data = sessionStorage.getItem('key')
    
    // console.log(data)
    if(data == null){
      window.location.href = "/#/";
    }
  }
  )

  useEffect(() => {
    axios.get(url)
    .then((res) =>{
      setNews(res.data);
      setloading(false)
    }).catch((e)=>{
      alert("Some Error")
      window.location.href = "/#/"
    })
  },[url])


  return (
    <React.Fragment>
      {loading?<Preloader/>:<p/>}
      <Grid container>
        <Grid xs={0} sm={2} />
        <Grid item container sm={8} spacing={3}>
            { news.map((item) =>{
              return (
                <Grid item xs={12} sm={12}>
                  <NewsCard
                    date={item?moment(item.updatedAt).format('LLL'):""}
                    title={item?item.headline:""}
                    brief={item?item.brief:""}
                    imgSrc={item?item.cphoto:""}
                    linkId={item?item._id:""}
                    views={item?item.views:""}
                    youtubelink={item?item.link:""}
                  />
                </Grid>
              )
            })} 
        </Grid>
        <Grid xs={0} sm={2} />
      </Grid>
    </React.Fragment>
  );
};

export default AdminNews;