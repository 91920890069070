let Statess = [
        {
        value: 1,
        label: "Andaman and Nicobar Islands"
        },
        {
        value: 2,
        label: "Andhra Pradesh"
        },
        {
        value: 3,
        label: "Arunachal Pradesh"
        },
        {
        value: 4,
        label: "Assam"
        },
        {
        value: 5,
        label: "Bihar"
        },
        {
        value: 6,
        label: "Chandigarh"
        },
        {
        value: 7,
        label: "Chhattisgarh"
        },
        {
        value: 8,
        label: "Dadra and Nagar Haveli"
        },
        {
        value: 9,
        label: "Daman and Diu"
        },
        {
        value: 10,
        label: "Delhi"
        },
        {
        value: 11,
        label: "Goa"
        },
        {
        value: 12,
        label: "Gujarat"
        },
        {
        value: 13,
        label: "Haryana"
        },
        {
        value: 14,
        label: "Himachal Pradesh"
        },
        {
        value: 15,
        label: "Jammu and Kashmir"
        },
        {
        value: 16,
        label: "Jharkhand"
        },
        {
        value: 17,
        label: "Karnataka"
        },
        {
        value: 18,
        label: "Kerala"
        },
        {
        value: 19,
        label: "Lakshadweep"
        },
        {
        value: 20,
        label: "Madhya Pradesh"
        },
        {
        value: 21,
        label: "Maharashtra"
        },
        {
        value: 22,
        label: "Manipur"
        },
        {
        value: 23,
        label: "Meghalaya"
        },
        {
        value: 24,
        label: "Mizoram"
        },
        {
        value: 25,
        label: "Nagaland"
        },
        {
        value: 26,
        label: "Odisha"
        },
        {
        value: 27,
        label: "Puducherry"
        },
        {
        value: 28,
        label: "Punjab"
        },
        {
        value: 29,
        label: "Rajasthan"
        },
        {
        value: 30,
        label: "Sikkim"
        },
        {
        value: 31,
        label: "Tamil Nadu"
        },
        {
        value: 32,
        label: "Telangana"
        },
        {
        value: 33,
        label: "Tripura"
        },
        {
        value: 34,
        label: "Uttar Pradesh"
        },
        {
        value: 35,
        label: "Uttarakhand"
        },
        {
        value: 36,
        label: "West Bengal"
        }
]

export default Statess;