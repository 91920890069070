const AWS = require("aws-sdk");

const BUCKET_NAME = "luckybucket44"
// "jhansitimes";
const IAM_USER_KEY = "AKIAZKDH55VDNI6N7SVJ"
//  "AKIAXHTAQJ7NECOL5WEM";
const IAM_USER_SECRET = "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"
const region = "us-east-1"
// "lp4j6kZJG/ouw616QI1Md+VN7VbBD8foRfk/mfbB";

function uploadToS3(file) {
  return new Promise((resolve, reject) => {
    let s3bucket = new AWS.S3({
      region,
      accessKeyId: IAM_USER_KEY,
      secretAccessKey: IAM_USER_SECRET,
      Bucket: BUCKET_NAME,
      AccessControlAllowOrigin: "*",
    });

    s3bucket.createBucket(function () {
      var params = {
        Bucket: BUCKET_NAME,
        Key: file.name,
        Body: file.data,
        ContentType: "image/jpg",
        ACL: "public-read",
      };
      s3bucket.upload(params, function (err, data) {
        if (err) {
          console.log("error in callback");
          console.log("s3 err", err);
          reject(err);
        } else {
          console.log("success");
          resolve(data.Location);
        }
      });
    });
  });
}

export default uploadToS3;
