import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: 45,
    paddingTop: "30%",
  },
  Card: {
    borderRadius: 13,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "3px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const NewsCard = (props) => {
  const classes = useStyles();
  const { date, title, brief, imgSrc, linkId, youtubelink } = props;
  // console.log("Upper link id " + linkId);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Do you want to Delete this News</h2>
      <p id="simple-modal-description">Press Yes to Delete News</p>
      <Link to={""}>
        <Button
          style={{ textAlign: "center" }}
          onClick={() => {
            const url = `https://jtback.onrender.com/deleteNews/${linkId}`;
            console.log("url " + url);
            console.log("linkID " + linkId);
            if (url) {
              axios
                .delete(url)
                .then(async (res) => {
                  if(youtubelink.length > 0) await axios.delete(`https://jtback.onrender.com/deletevideo/${linkId}`)

                  console.log("News deleted successfully");
                  alert("News deleted successfully");
                })
                .catch((e) => {
                  console.log("Error occured");
                  alert("Cannot Delete News , Some error occured");
                });
            }
          }}
        >
          YES
        </Button>
      </Link>
    </div>
  );

  return (
    <Card className={classes.Card}>
      <CardHeader title={title} subheader={date} />
      <CardActionArea>
        <Link to={`/FullNews/${linkId}`}>
          <CardMedia className={classes.media} image={imgSrc} />
        </Link>
      </CardActionArea>
      <CardContent>
        <Typography variant="body2" component="p">
          {brief}
          <br />
        </Typography>
      </CardContent>
      <CardActions>
        <Link to={`/UpdateNews/${linkId}`}>
          <Button size="medium" variant="contained" color="primary">
            Update News
          </Button>
        </Link>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          onClick={handleOpen}
          startIcon={<DeleteIcon />}
        >
          Delete News
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </CardActions>
    </Card>
  );
};

export default NewsCard;
