import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Test2.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Embed from "@editorjs/embed";
import { makeStyles } from "@material-ui/core";
import ImageTool from "@editorjs/image";
import Tags from "../data/tag";
import Statess from "../data/Statess";
import Select from "react-select";
import Preloader from "../Preloader/Preloader";
import uploadToS3 from "./../../awss3";

const useStyle = makeStyles({
  texteditor: {
    border: "1px solid black",
  },
});

export default function Test2() {
  const [headline, setheadline] = useState("");
  const [url, seturl] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [brief, setbrief] = useState("");
  const [description, setdescription] = useState([]);
  const [link, setlink] = useState("");
  const [tag, settag] = useState([]);
  const [views, setviews] = useState(0);
  const [editor, seteditor] = useState("");
  const [files, setfiles] = useState(null);
  // const [photo, setphoto] = useState([]);
  const [editorjs, setEditor] = useState(null);
  const [loading, setloading] = useState(false);
  const [image, setimage] = useState("");

  useEffect(() => {
    let data = sessionStorage.getItem("key");

    // console.log(data)
    if (data == null) {
      window.location.href = "/#/";
    }
  });

  useEffect(() => {
    setEditor(
      new EditorJS({
        holder: "editorjs",
        inlineToolbar: true,
        placeholder: "Let`s write an awesome story!",
        logLevel: "ERROR",
        data: description,
        tools: {
          header: {
            class: Header,
            inlineToolbar: ["link"],
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          image: {
            class: ImageTool,
            config: {
              uploader: {
                uploadByFile: uploadImage,
              },
            },
          },

          embed: {
            class: Embed,
            inlineToolbar: true,
            config: {
              services: {
                youtube: true,
                coub: true,
              },
            },
          },
        },
      })
    );
  }, []);

  const classes = useStyle();

  function add() {
    setloading(true);
    // console.log(tag)
    let content = {
      headline: headline,
      city: city,
      state: state,
      country: country,
      brief: brief,
      description: description,
      link: link,
      views: views,
      editor: editor,
      cphoto: image,
      tag: tag,
      url: url,
    };
    axios({
      method: "post",
      url: "https://jtback.onrender.com/writeNews",
      data: content,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        if(content.link.length > 0)
        {
        await axios({
          method:"POST",
          url:"https://jtback.onrender.com/video",
          data:{
            link:content.link,
            newsid:res.data._id
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
      }
        if (res.status === 201) {
          console.log(res);
          // console.log("success");
          // console.log(content);
          alert("success");
          window.location.href = "/#/AdminNews";
        }
      })
      .catch((err) => {
        setloading(false);
        alert("Error occured");
        if (err.response) {
          alert(err.response.data.message);
          // console.log(err.response.data.message);
        }
      });
  }
  function addtag(e) {
    settag(Array.isArray(e) ? e.map((x) => x.label) : []);
  }

  function pop() {
    const ele = document.getElementById("pop");
    const dis = ele.style.display;
    if (dis === "none") {
      ele.style.display = "block";
    } else {
      ele.style.display = "none";
    }
  }

  const uploadImage = async (param) => {
    setloading(true);
    let articleImg;
    if (param.name) {
      articleImg = param;
    } else {
      articleImg = files[0];
    }

    const url = await uploadToS3({
      name: `${new Date().getTime()}.jpg`,
      data: articleImg,
    })
      .then((data) => data)
      .catch((err) => {
        console.log(err);
      });

    if (param.name) {
      setloading(false);
      return {
        success: 1,
        file: {
          url: url,
        },
      };
    }
  };

  const uploadImage2 = async (param) => {
    setloading(true);
    if (!files) {
      setloading(false);
      alert("insert image");
    } else {
      let articleImg;
      if (param.name) {
        articleImg = param;
      } else {
        articleImg = files[0];
      }

      uploadToS3({
        name: `${new Date().getTime()}.jpg`,
        data: articleImg,
      })
        .then((data) => {
          console.log("then", data);
          setimage(data);
          setloading(false)
        })
        .catch((err) => {
          console.log(err);
        });

      // setloading(false);
    }
  };

  const handleEditorClick = () => {
    editorjs
      .save()
      .then((outputData) => {
        setdescription(outputData);
      })
      .catch((error) => {});
    pop();
  };

  return (
    <div>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "70%",
          marginBottom: "100px",
        }}
      >
        {loading ? <Preloader /> : <p />}

        <div>
          <label className="label">Headline</label>
          <input
            type="text"
            className="form-control"
            className="input1"
            style={{ width: "100%" }}
            value={headline}
            onChange={(e) => {
              setheadline(e.target.value);
            }}
          />
        </div>
        <div>
          <label className="label">URL</label>
          <input
            type="text"
            className="form-control"
            className="input1"
            style={{ width: "100%" }}
            value={url}
            onChange={(e) => {
              seturl(e.target.value.replace(/[^a-zA-Z0-9]/g,"-"));
            }}
          />
        </div>
        <div>
          <label className="label">city</label>
          <input
            type="text"
            className="form-control"
            className="input1"
            style={{ width: "100%" }}
            value={city}
            onChange={(e) => {
              setcity(e.target.value);
            }}
          />
        </div>
        <div>
          <label className="label">State</label>
          <Select
            options={Statess}
            onChange={(e) => {
              setstate(e.label);
            }}
          ></Select>
        </div>
        <div>
          <label className="label">country</label>
          <input
            type="text"
            className="form-control"
            className="input1"
            style={{ width: "100%" }}
            value={country}
            onChange={(e) => {
              setcountry(e.target.value);
            }}
          />
        </div>
        <div>
          <label className="label">Tags</label>
          <h6 style={{ color: "red", border: "1px red solid" }}>{tag + " "}</h6>
          <Select isMulti options={Tags} onChange={addtag}></Select>
        </div>
        <div>
          <label className="label">brief</label>
          <input
            type="text"
            className="form-control"
            className="input1"
            style={{ width: "100%" }}
            value={brief}
            onChange={(e) => {
              setbrief(e.target.value);
            }}
          />
        </div>
        <div>
          <label className="label">link</label>
          <input
            type="text"
            className="form-control"
            className="input1"
            style={{ width: "100%" }}
            value={link}
            onChange={(e) => {
              setlink(e.target.value);
            }}
          />
        </div>
        {/* <div>
          <label className="label">views</label>
          <input
            type="number"
            className="form-control"
            className = "input1"
            style={{ width: "100%" }}
            value={views}
            onChange={(e) => {
              setviews(e.target.value);
            }}
          />
        </div> */}
        <div>
          <label className="label">editor</label>
          <input
            type="text"
            className="form-control"
            className="input1"
            style={{ width: "100%" }}
            value={editor}
            onChange={(e) => {
              seteditor(e.target.value);
            }}
          />
        </div>
        <div>
          <label className="label">Cover Photo</label>
          <br />
          <input
            className="form-control"
            className="input1"
            style={{ width: "100%" }}
            type="file"
            name="file"
            placeholder="choose"
            onChange={(e) => {
              // console.log("input file");
              setfiles(e.target.files);
            }}
            required
          ></input>
          <br />
          <button
            type="submit"
            className="btn btn-info"
            style={{
              width: "20%",
              marginLeft: "40%",
              marginTop: "10px",
              minWidth: "100px",
            }}
            onClick={uploadImage2}
          >
            Upload image
          </button>
          <br />
          <img
            src={image}
            style={{
              maxHeight: "200px",
              borderRadius: "20px",
              width: "50%",
              marginLeft: "25%",
            }}
          ></img>
        </div>
        <label className="label">Description</label>
        <div id="editorjs" className={classes.texteditor}></div>
        <br></br>
        <button
          type="button"
          className="btn btn-info"
          style={{ width: "25%", marginLeft: "37.5%", minWidth: "100px" }}
          // className="btn btn-success"
          onClick={(e) => {
            e.preventDefault();
            handleEditorClick();
          }}
        >
          Save Article
        </button>
        <div
          id="pop"
          style={{
            width: "100%",
            display: "none",
            position: "fixed",
            zIndex: 1,
            left: 0,
            top: 0,
            // marginLeft: "25%",
            height: "100%",
            overflow: "auto",
            background: "rgba(0,0,0,0.4)",
          }}
        >
          <div className="modal-content">
            <button onClick={pop} style={{ width: "100px" }} >
              X-close
            </button>
            <p style={{ textAlign: "center" }}>
              Description Added Successfully
            </p>
          </div>
        </div>
        {/* <br></br> */}
        <div style={{ marginTop: "30px" }}>
          <button
            type="reset"
            style={{ width: "40%", marginLeft: "30%", minWidth: "100px" }}
            className="btn btn-secondary"
            onClick={(e) => {
              window.location.reload();
            }}
          >
            reset
          </button>
          <h1>{""}</h1>

          <button
            type="button"
            style={{ width: "50%", marginLeft: "25%", minWidth: "100px" }}
            className="btn btn-success"
            onClick={(e) => {
              e.preventDefault();
              add();
            }}
          >
            Submit
          </button>
        </div>
      </div>
      <div style={{ height: "200px" }}></div>
    </div>
  );
}
