import React ,{useEffect}from "react";
import cx from "clsx";
import Color from "color";
import GoogleFontLoader from "react-google-font-loader";
import NoSsr from "@material-ui/core/NoSsr";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { useCoverCardMediaStyles } from "@mui-treasury/styles/cardMedia/cover";
import { Row, Item } from "@mui-treasury/components/flex";
import { Link } from "react-router-dom";

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    overflow: "auto",
    [breakpoints.only("xs")]: {
      "& > *:not(:first-child)": {
        paddingLeft: 0,
      },
    },
    [breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
}));

const useStyles = makeStyles(({ palette }) => ({
  color: ({ color }) => ({
    "&:before": {
      backgroundColor: Color(color).darken(0.3).desaturate(0.2).toString(),
    },
  }),
  root: {
    position: "relative",
    borderRadius: "1rem",
    minWidth: 320,
    "&:before": {
      transition: "0.2s",
      position: "absolute",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      borderRadius: "1rem",
      zIndex: 0,
      bottom: 0,
    },
    "&:hover": {
      "&:before": {
        bottom: -6,
      },
    },
  },
  cover: {
    borderRadius: "1rem",
  },
  content: ({ color }) => ({
    position: "relative",
    zIndex: 1,
    borderRadius: "1rem",
    boxShadow: `0 6px 16px 0 ${Color(color).fade(0.5)}`,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 0,
      width: "100%",
      height: "100%",
      clipPath:
        "polygon(0% 100%, 0% 35%, 0.3% 33%, 1% 31%, 1.5% 30%, 2% 29%, 2.5% 28.4%, 3% 27.9%, 3.3% 27.6%, 5% 27%,95% 0%,100% 0%, 100% 100%)",
      borderRadius: "1rem",
      background: `linear-gradient(to top, ${color}, ${Color(color)
        .rotate(24)
        .lighten(0.12)})`,
    },
  }),
  title: {
    fontFamily: "Fjalla One",
    fontSize: "1.25rem",
    color: "#fff",
    margin: 0,
  },
  team: {
    fontFamily: "Sen",
    fontSize: "1rem",
    color: "white"
    // color: palette.text,
  },
}));

const CustomCard = ({ styles, cover, LinkPage, title, Detail }) => {
  useEffect(()=>{
    let data = sessionStorage.getItem('key')
    
    // console.log(data)
    if(data == null){
      window.location.href = "/#/";
    }
  }
  )
  const mediaStyles = useCoverCardMediaStyles();
  const linkSstyle = {
    textDecoration: "none",
  };
  return (
    <Box className={cx(styles.root, styles.color)} pt={20}>
      <Link to={`/${LinkPage}`} style={linkSstyle}>
        <CardMedia
          image={cover}
          className={styles.cover}
          classes={mediaStyles}
        />
        <Box className={styles.content} p={2}>
          <Box position={"relative"} zIndex={1}>
            <Row p={0} gap={2}>
              <Item position={"bottom"}>
                <h2 className={styles.title}>{title}</h2>
              </Item>
            </Row>
            <Row mt={4} alignItems={"center"}>
              <Item>
                <div className={styles.team}>{Detail}</div>
              </Item>
            </Row>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export const AdminHomeCard = React.memo(function HighlightCard() {
  const styles1 = useStyles({ color: "#fc7944" });
  const styles2 = useStyles({ color: "#5357ce" });
  const gridStyles = useGridStyles();
  return (
    <>
      <NoSsr>
        <GoogleFontLoader
          fonts={[{ font: "Fjalla One" }, { font: "Sen", weights: [500] }]}
        />
      </NoSsr>
      <Grid container>
        <Grid
          style={{ padding: 16 }}
          classes={gridStyles}
          container
          spacing={2}
          item
          xs={12}
        >
          <Grid item>
            <CustomCard
              styles={styles1}
              Detail={"Use this to upload News"}
              cover={
                "https://images.unsplash.com/photo-1540312479395-6b36e14a5961?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=334&q=80"
              }
              LinkPage={"Create"}
              title={<>Create News</>}
            />
          </Grid>
          <Grid item>
            <CustomCard
              styles={styles2}
              Detail={"Use this to Read, Update, Delete News"}
              cover={
                "https://images.unsplash.com/photo-1585776245991-cf89dd7fc73a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
              }
              LinkPage={"AdminNews"}
              title={<>View News</>}
            />
          </Grid>
          {/* <Grid item>
            <CustomCard
              styles={styles1}
              Detail={"Use this to upload News"}
              cover={
                "https://images.unsplash.com/photo-1531315630201-bb15abeb1653?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80"
              }
              LinkPage={"CreateAd"}
              title={<>Upload advertisement</>}
            />
          </Grid> */}
          {/* <Grid item>
            <CustomCard
              styles={styles2}
              Detail={"Use this to Delete Advertisement"}
              cover={
                "https://images.unsplash.com/photo-1507608158173-1dcec673a2e5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
              }
              LinkPage={"AdminNews"}
              title={<>Delete advertisement</>}
            />
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
});

export default AdminHomeCard;
