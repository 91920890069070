import React, { useState } from "react";
import { Button, List, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyle = makeStyles({
  list: {
    display: "flex",
  },
  text: {
    fontWeight: "550",
  },
  buttonMargin: {
    margin: "10px",
  },
});
const AppbarList = (props) => {
  const handleItemClick = (item) => {
    setSelection(item);
  };

  const linkStyle = {
    textDecoration: "none",
  };

  const [selection, setSelection] = useState("Home");
  const classes = useStyle();
  return (
    <React.Fragment>
      <List className={classes.list}>
        <Link style={linkStyle} to="/">
          <Button
            className={classes.buttonMargin}
            color="primary"
            variant={selection === "Home" ? "contained" : "text"}
            disableRipple
            onClick={() => {
              handleItemClick("Home");
            }}
          >
            <Typography
              className={classes.text}
              color={selection === "Home" ? "secondary" : "primary"}
            >
              होम
            </Typography>
          </Button>
        </Link>
      </List>
    </React.Fragment>
  );
};

export default AppbarList;
