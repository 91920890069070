import React, { useState , useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";

// import { Route, Switch } from "react-router-dom";
// import AdminHomeCard from "../Content/AdminHomeCard";
export default function Admin() {
  useEffect(()=>{
    let data = sessionStorage.getItem('key')
    console.log(data)
    if(data != null){
      // console.log("coming")
      window.location.href = "/#/dashboard";
    }
  }
  )
  const [secret, setsecret] = useState("");

  function add() {
    if (secret === "jhansitimes@13579") {
      sessionStorage.setItem('key', true);
      console.log("working");
      window.location.href = "/#/dashboard";
    } else {
      console.log("not working");
      alert("Wrong Secret key")
      window.location.reload();
    }
  }

  return (
    <div style={{ marginLeft: "auto", marginRight: "auto", width: "50%" }}>
      <div>
        <label style={{ width: "50%", marginLeft: "25%", textAlign: "center" }}>
          Enter Secret key
        </label>
        <input
          type="password"
          className="form-control"
          style={{ width: "100%" }}
          value={secret}
          onChange={(e) => {
            setsecret(e.target.value);
          }}
        />
      </div>
      <button
        type="button"
        style={{ width: "50%", marginLeft: "25%", marginTop: "20px" }}
        className="btn btn-success"
        onClick={(e) => {
          e.preventDefault();
          add();
        }}
      >
        Submit
      </button>
    </div>
  );
}
