import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Hidden,
} from "@material-ui/core";
import AppbarList from "./AppbarList";

import { Link } from "react-router-dom";

const useStyle = makeStyles((theme) => {
  return {
    root: {
      zIndex: 5,
    },
    toolbarbutton: {
      display: "flex",
      flex: 1,
    },
    logo: {
      height: "51px",
      width: "104px",
      marginLeft: "10px",
      marginRight: "20px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxHeight: "70vh",
      maxWidth: "70vh",
      display: "flex",
      flexDirection: "column",
      overflowY: "scroll",
    },
  };
});

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);

  const handleMoreClick = () => {
    setMoreOpen(!moreOpen);
  };

  const handleModalClick = () => {
    setMenuOpen(!menuOpen);
  };

  const classes = useStyle();
  return (
    <React.Fragment>
      <AppBar
        position="static"
        color="transparent"
        className={classes.root}
        elevation={0}
      >
        <Toolbar>
          <Link to="/">
            <img src="/logo.png" alt="logo" className={classes.logo} />
          </Link>

          <div className={classes.toolbarbutton}>
            <Hidden xsDown>
              <AppbarList moreclick={handleMoreClick} />
            </Hidden>
          </div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
